body {
  margin: 0;
  font-family:'Inter' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Inter' !important;
}
