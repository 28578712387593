* {
  font-family: Inter !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.swal-title {
  color: #231f20 !important;
  font-family: Inter;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin: 5% 5% !important;
}
.swal-text {
  color: #000 !important;
  font-family: Inter;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin: 5% 5% !important;
}
.confirm-modal-button {
  width: 211px;
  height: 72px;
  margin: 0 0 0 15px;
  /* padding: 13px 77px 15px 78px; */
  border-radius: 4px;
  background-color: #231f20 !important;
  border: #231f20 !important;
}

.cancel-modal-button {
  width: 211px;
  height: 72px;
  margin: 0 15px 0 0;
  padding: 23px 70px 25px 73px;
  border-radius: 4px;
  border: solid 1px #dc3545 !important;
  background-color: #fff !important;
  color: #dc3545 !important;
}

.confirm-modal-button:hover {
  width: 211px;
  height: 72px;
  margin: 0 0 0 15px;
  /* padding: 23px 87px 25px 88px; */
  border-radius: 4px;
  background-color: #231f20 !important;
  border: #231f20 !important;
}

.cancel-modal-button:hover {
  width: 211px;
  height: 72px;
  margin: 0 15px 0 0;
  padding: 23px 70px 25px 73px;
  border-radius: 4px;
  border: solid 1px #dc3545 !important;
  background-color: #fff !important;
  color: #dc3545 !important;
}

.cancel-modal-button:active {
  width: 211px;
  height: 72px;
  margin: 0 15px 0 0;
  padding: 23px 70px 25px 73px;
  border-radius: 4px;
  border: solid 1px #dc3545 !important;
  background-color: #fff !important;
  color: #dc3545 !important;
}

.swal-icon {
  background-color: #DC3545 !important;
  color: #fff !important;
  border: solid #DC3545 !important;
}

.swal-icon-success {
  background-color: #2BAA80 !important;
  color: #fff !important;
  border: solid #2BAA80 !important;
}

.no-bullets {
  list-style-type: none;
}

.swal-content-container {
  text-align: start !important;
  align-items: start;
}

.swal-content-title {
  font-family: Inter !important;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8794a1 !important;
}

.swal-content-value {
  font-family: Inter !important;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #231f20 !important;
  float: right;
}

.swal-content-fee {
  font-family: Inter !important;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8794a1 !important;
  float: right;
}

.swal-content-container li {
  margin-top: 4% !important;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.swal-icon-loader {
  background-color: #fff !important;
  color: #fff !important;
  border: solid #fff !important;
  margin-top: 20%;
  width: 10%;
  animation: rotate 5s linear infinite;
  margin-bottom: 10%;
}

.contacting-server-title {
  font-family: Inter;
  font-size: 23px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  margin-top: 10%;
}

.contacting-server-text {
  font-family: Inter;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.swal-content {
  font-family: Inter !important;
}